import {
    Container, Grid, Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import React from 'react';
import { LoggedInUser, UserProfileViewModel } from '../../../types/auth.types';
import { JobSearchResultViewModel } from '../../../types/jobs.types';
import { safeStringify } from '../../utils/escape';
import { updateQueryStringParameter } from '../../utils/urlUtils';
import JobItem from '../components/JobItem';
import JobItemList from '../containers/JobItemList/JobItemList.container';
import Anchor from '../foundation/Anchor';
import CustomBR from '../foundation/CustomBR';
import { Flexbox } from '../foundation/Flex';
import Page from '../layout/base.page';
import SmartAgentModal from '../modals/SmartAgentModal';

export type JobsPageProps = {
    url: string,
    user?: LoggedInUser,
    job?: JobSearchResultViewModel,
    userProfile?: UserProfileViewModel,
    title: string,
    description: string,
    similarJobs: JobSearchResultViewModel[],
    h1: string,
    isAndroidUser?: boolean,
}

const ROOT_TAG_LEVEL = 1;

type JobsPageState = {
    showSmartAgentModal: boolean
} & JobsPageProps;

const JobDetailsAdSense = () => {
    React.useEffect(() => {
        try {
            document.addEventListener('DOMContentLoaded', () => {
                (window.adsbygoogle = window.adsbygoogle || []).push({});
            });
        } catch (e) {
            console.log('adsbygoogle err ', e);
        }
    }, []);

    return <div>
        <ins className="adsbygoogle"
            style={{ display: 'block' }}
            data-ad-client="ca-pub-7369058349181167"
            data-ad-slot="3114591493"
            data-ad-format="auto"
            data-full-width-responsive="true" />
    </div>;
};

class JobDetailsPage extends React.Component<JobsPageProps, JobsPageState> {
    constructor(props) {
        super(props);
        this.state = {
            ...props,
            showSmartAgentModal: false,
        };
    }

    renderMoreLikeThis() {
        const { job } = this.props;
        if (!job) {
            return <div>
                <Anchor variant="h4" fontWeight="bold" href="/job/search" text="עבור לחיפוש משרות..." />
            </div>;
        }

        const {
            tags, formatted_address, latitude, longitude,
        } = job;

        const query = latitude ? `?lat=${latitude}&lng=${longitude}&address=${formatted_address}` : '';
        const url = tags && tags.length > 0
            ? `/job/tagged/${tags.map(t => t.display).join('+')}${query}`
            : `/job/search/${query}`;

        return <div>
            <Anchor variant="h4" fontWeight="bold" href={url} text="הצג משרות דומות נוספות..." />
        </div>;
    }

    getBreadCrumbs() {
        const crumbs = [];

        const { job } = this.state;
        if (job.tags && job.tags.length > 0) {
            const rootTags = job.tags.filter(t => t.level === ROOT_TAG_LEVEL);
            if (rootTags && rootTags.length > 0) {
                crumbs.push({
                    text: rootTags[0].tag,
                    url: `/job/tagged/${rootTags[0].display}`,
                });
            }

            const noneRootTags = job.tags.filter(t => t.level !== ROOT_TAG_LEVEL);
            if (noneRootTags && noneRootTags.length > 0) {
                crumbs.push({
                    text: noneRootTags[0].tag,
                    url: `/job/tagged/${noneRootTags[0].display}`,
                });
            }
        }

        crumbs.push({
            text: job.title,
        });

        return crumbs;
    }

    getJobPostingSchema() {
        const { job } = this.state;
        const jobPostingSchema: any = {
            '@context': 'https://schema.org/',
            '@type': 'JobPosting',
            title: job.title,
            description: job.description,
            datePosted: job.updated_at,
            validThrough: dayjs(job.updated_at).add(14, 'days'),
            jobLocation: {
                '@type': 'Place',
                address: {
                    '@type': 'PostalAddress',
                    addressLocality: job.formatted_address,
                    addressCountry: 'IL',
                },
            },
        };

        if (job.tags) {
            const tagNames = job.tags.map(t => t.tag);
            jobPostingSchema.industry = tagNames.join(', ');
            if (tagNames.indexOf('משרה מלאה') !== -1) jobPostingSchema.employmentType = 'FULL_TIME';
            else if (tagNames.indexOf('משרה חלקית') !== -1) jobPostingSchema.employmentType = 'PART_TIME';

            if (tagNames.indexOf('עבודה מהבית') !== -1) {
                jobPostingSchema.jobLocationType = 'TELECOMMUTE';
                jobPostingSchema.applicantLocationRequirements = {
                    '@type': 'Country',
                    name: 'IL',
                };
            }
        }

        jobPostingSchema.hiringOrganization = {
            '@type': 'Organization',
            name: job.company ? job.company.name : 'Mploy דרושים',
        };

        return safeStringify(jobPostingSchema);
    }

    updateUserProfile = (userProfile: UserProfileViewModel) => { this.setState({ userProfile }); };

    updateUser = (user: LoggedInUser) => { this.setState({ user }); };

    updateJobs = (jobs: JobSearchResultViewModel[]) => { this.setState({ job: jobs[0] }); };

    openSmartAgent = ev => {
        ev.preventDefault();
        this.setState({ showSmartAgentModal: true });
    };

    closeSmartAgent = () => { this.setState({ showSmartAgentModal: false }); };

    gotoLoginAndRedirectSettings = () => {
        window.history.pushState({}, '', updateQueryStringParameter('redirect', '/user/settings'));
        window.modals.toggleLoginModal();
    };

    render() {
        return <Page
            breadCrumbs={this.getBreadCrumbs()}
            url={this.state.url} user={this.state.user}
            pageHeaderTitle={this.state.h1 || this.state.title}>
            <>
                {this.props.job ? <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: this.getJobPostingSchema() }} /> : ''}
                <CustomBR />
                <SmartAgentModal
                    gotoLogin={this.gotoLoginAndRedirectSettings}
                    user={this.props.user}
                    defaultTags={this.props.job.tags}
                    onClose={this.closeSmartAgent} open={this.state.showSmartAgentModal} />
                <Container>
                    <Flexbox flexDirection="row-reverse">
                        <Anchor href="#" text="הזמן שלך חשוב? הגדר סוכן חכם" onClick={this.openSmartAgent} />
                    </Flexbox>

                    {this.props.isAndroidUser && <>
                        <CustomBR />
                        <Flexbox onClick={() => {
                            window.open('https://play.google.com/store/apps/details?id=com.ckc.wordex', '_blank');
                        }} border="1px solid #005bc0" borderRadius="10px" padding="10px" style={{ backgroundColor: '#f0f8ff' }}>
                            <Typography>
                                <b>חשובים לכם ההישגים המקצועיים? </b>אוצר מילים באנגלית הוא המפתח להצלחה בקריירה!
                                <span style={{ color: '#005bc0' }}><b><u> הורידו עכשיו את Wordex</u></b></span>
                            </Typography>
                            <img src="https://wordex.ai/images/wordex-logo.png" alt="wordex words" width={100} height={100} />
                        </Flexbox>
                    </>}
                    <JobItemList
                        showFullDetails
                        showDetailsLink={false}
                        showRemoveJob={false}
                        showPhoneBtn
                        onJobsChange={this.updateJobs}
                        user={this.state.user}
                        userProfile={this.state.userProfile}
                        onUserChange={this.updateUser}
                        onUserProfileChange={this.updateUserProfile}
                        jobs={[this.state.job]} />
                    <CustomBR />
                    <JobDetailsAdSense />
                    <CustomBR />
                    <Typography variant="h3" color="secondary" fontWeight="bold">
                        משרות דומות שיכולות לעניין אותך
                    </Typography>
                    <CustomBR />
                    <Grid container spacing={3}>
                        {this.props.similarJobs?.map(job => <Grid key={job.jobid} item md={4} sm={12}>
                            <JobItem
                                onReadMoreClick={() => window.location.assign(`/job/details/${job.jobid}`)}
                                lightMode
                                showFullDetails={false}
                                job={job} />
                        </Grid>)}
                    </Grid>
                    <CustomBR />
                    {this.renderMoreLikeThis()}
                    <CustomBR />
                </Container>
            </>
        </Page>;
    }
}

export default JobDetailsPage;
