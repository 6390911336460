import { Container } from '@mui/material';
import React from 'react';
import styled from 'styled-components';
import CustomBR from '../foundation/CustomBR';

const ContainerCentered = styled(Container)`
    text-align: center;
    min-height: 280px;
    ${props => props.theme.breakpoints.down('sm')} {
        min-height: 355px;
    }
`;

export default class NavbarAd extends React.Component {
    componentDidMount() {
        try {
            document.addEventListener('DOMContentLoaded', () => {
                (window.adsbygoogle = window.adsbygoogle || []).push({});
            });
        } catch (e) {
            console.log('adsbygoogle err ', e);
        }
    }

    render() {
        return (
            //  className="navbar-ad"
            <ContainerCentered>
                <CustomBR />
                <ins className="adsbygoogle"
                    style={{ display: 'block' }}
                    data-ad-client="ca-pub-7369058349181167"
                    data-ad-slot="9660423325"
                    data-ad-format="auto" />
            </ContainerCentered>
        );
    }
}
