export const sellItemIds = {
    /**
     * just a regular internal job. promotion is 0
     */
    regularJob: 'regular-job',
    /**
     * promoted job, with promotion 1 (in jobs table)
     */
    promotedJob: 'promoted-job',
    bundle3Plus1Job: 'bundle-3-plus-1-job',
    credits: 'credits',
} as const;

export type SellItemIdsKeys = keyof typeof sellItemIds;
export type SellItemIdsValues = typeof sellItemIds[SellItemIdsKeys]

export const sellItems = {
    [sellItemIds.credits]: {
        id: sellItemIds.credits,
        unitprice: 20,
        description: 'קרדיטים לצפייה בפרטי מועמדים רלוונטים. קרדיט 1 = מועמד 1',
        calculateDiscount(quantity: number) {
            if (quantity <= 9) return sellItems[sellItemIds.credits].unitprice;
            if (quantity <= 29) return parseFloat((0.9 * sellItems[sellItemIds.credits].unitprice).toFixed(2));
            return parseFloat((0.8 * sellItems[sellItemIds.credits].unitprice).toFixed(2));
        },
    },
    [sellItemIds.regularJob]: {
        id: sellItemIds.regularJob,
        unitprice: 190,
        description: 'פרסום משרה',
        calculateDiscount(quantity: number) {
            if (quantity <= 2) return sellItems[sellItemIds.regularJob].unitprice;
            if (quantity <= 4) return 170;
            if (quantity <= 9) return 150;
            return 140;
        },
    },
    [sellItemIds.promotedJob]: {
        id: sellItemIds.promotedJob,
        unitprice: 290,
        description: 'פרסום משרה מקודמת',
        calculateDiscount(quantity: number) {
            if (quantity <= 2) return sellItems[sellItemIds.promotedJob].unitprice;
            if (quantity <= 4) return 250;
            if (quantity <= 9) return 230;
            return 210;
        },
    },
    [sellItemIds.bundle3Plus1Job]: {
        id: sellItemIds.bundle3Plus1Job,
        unitprice: 770,
        description: 'פרסום 3 משרות רגילות ומשרה אחת מקודמת',
        calculateDiscount() {
            return sellItems[sellItemIds.bundle3Plus1Job].unitprice;
        },
    },
} as const;
