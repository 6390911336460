import { PromotionTypesValues } from '../../common/promotion_type.enum';
import { JobTypesValues } from '../../types/jobs.types';
import { API_METHODS } from './api.enums';
import { apiAction } from './utils.actions';

export type ExtendJobPromotionPayload = {
    jobid: number
    period: number
    promoted?: boolean
}

export const extendJobPromotions = (
    payload: ExtendJobPromotionPayload, backdrop = true,
) => apiAction<void>({
    url: '/promotions/extend',
    method: API_METHODS.POST,
    payload,
    backdrop,
});

type PromoteJobPayload = {
    jobType: JobTypesValues
}

export const promoteJob = (
    params: { jobid: number }, payload: PromoteJobPayload, backdrop = true,
) => apiAction<void>({
    url: `/jobs/${params.jobid}/promote`,
    method: API_METHODS.POST,
    payload,
    backdrop,
});

export type AddPromotionPayload = {
    userid: number
    type: PromotionTypesValues
    amount: number
}

export const addPromotions = (
    payload: AddPromotionPayload, backdrop = true,
) => apiAction<void>({
    url: '/promotions',
    method: API_METHODS.POST,
    payload,
    backdrop,
});

type UsePromotionCreditsReqBody = {
    type: PromotionTypesValues,
    amount: number,
    userid: number
    jobid: number
};

export const usePromotions = (
    payload: UsePromotionCreditsReqBody, backdrop = true,
) => apiAction<void>({
    url: '/promotions/use-credits',
    method: API_METHODS.POST,
    payload,
    backdrop,
    globalError: true,
});

export const removePromotions = (
    params: {promotionId: number}, backdrop = true,
) => apiAction<void>({
    url: `/promotions/${params.promotionId}`,
    method: API_METHODS.DELETE,
    backdrop,
});
