export default {
    elastic: {
        host: 'http://localhost:9200',
    },
    analytics: {
        tid: 'UA-87664865-1',
    },
    redis: {
        port: 6379,
    },
    baseUrl: 'https://www.mploy.co.il/',
    gotenbergBaseUrl: 'http://127.0.0.1:3005',
    cvPath: '/home/chikichak/public_html/usersCV/',
    companiesLogosPath: '/home/chikichak/public_html/companiesLogos/',
    dbconnection: {
        username: 'chikichak_utf',
        password: 'JrHFdWArD93CGabw',
        database: 'chikichak_utf',
        host: 'localhost',
        dialect: 'mariadb',
        port: '3306',
        timezone: '+02:00',
        logging: false,
        dialectOptions: {
            supportBigNumbers: true,
        },
    },
    facebook: {
        clientID: '1302211316473586',
        clientSecret: 'a882cbe63a19a3fdf2761ce4510f53b9',
        callbackURL: 'https://www.mploy.co.il/login/facebook/success',
        enableProof: true,
        profileFields: [
            'id',
            'displayName',
            'name',
            'photos',
            'email',
        ],
    },
    linkedin: {
        clientID: '81ubcebf58uv1u',
        clientSecret: 'ceGFmExvHrovGMgO',
        callbackURL: 'https://mploy.co.il/login/linkedin/success',
        scope: ['r_emailaddress', 'r_liteprofile'],
    },
    gmail: {
        clientID: '486038827943-9qu0cvtqs38fonmbcq28g1181ce0ebnf.apps.googleusercontent.com',
        clientSecret: 'HuIgRtuqGbzM9BtTrqlGf0F7',
        callbackURL: 'https://mploy.co.il/login/gmail/success',
    },
    contact: {
        email: 'info@mploy.co.il',
        phone: '0544-700-720',
    },
};
