export default {
    elastic: {
        host: 'http://localhost:9200',
        log: 'trace',
    },
    analytics: {
        tid: 'UA-87664865-1',
    },
    redis: {
        port: 6379,
        // start redis command (for windows)
        // docker run --name redis-mploy -d -p 6379:6379 redis
        // docker start redis-mploy
        // host: '192.168.99.100', // docker machine, for windows `docker-machine ip`
    },
    baseUrl: 'http://localhost/',
    gotenbergBaseUrl: 'http://localhost:3005',
    cvPath: './usersCV/',
    companiesLogosPath: './companiesLogos/',
    dbconnection: {
        username: 'chikichak_utf',
        password: 't9fJyyfLwRCG4Gv6',
        database: 'chikichak_utf_new',
        // working with MAC
        // database: 'chikichak_utf',
        // port: 3303,
        host: 'localhost',
        dialect: 'mariadb',
        port: 3306,
        timezone: '+02:00',
        logging: console.log,
        dialectOptions: {
            supportBigNumbers: true,
        },
    },
    facebook: {
        clientID: '425446755384383', // production: '1302211316473586', // = APP_ID
        clientSecret: 'b060579c4cd7f6be2074dad073e663e0',
        callbackURL: 'http://localhost/login/facebook/success',
        enableProof: true,
        profileFields: [
            'id',
            'displayName',
            'name',
            'photos',
            'email',
        ],
    },
    linkedin: {
        clientID: '81ubcebf58uv1u',
        clientSecret: 'ceGFmExvHrovGMgO',
        callbackURL: 'http://localhost/login/linkedin/success',
        scope: ['r_emailaddress', 'r_liteprofile', 'rw_company_admin'],
    },
    gmail: {
        clientID: '486038827943-9qu0cvtqs38fonmbcq28g1181ce0ebnf.apps.googleusercontent.com',
        clientSecret: 'HuIgRtuqGbzM9BtTrqlGf0F7',
        callbackURL: 'http://localhost/login/gmail/success',
    },
    contact: {
        email: 'ariel@mploy.co.il',
        phone: '0544-700-720',
    },
};
