export const promotionTypes = {
    /**
     * posting jobs
     */
    jobs: 1,
    /**
     * promoted jobs
     */
    promotion: 2,
    /**
     * posting jobs model "mishbazot"
     * means that user can post up to X jobs at the same time with this promotion
     * so if he freeze on job, he can post a different job during this time.
     */
    jobs_tiles: 3,
    /**
     * posting promotion jobs model "mishbazot"
     */
    promotion_jobs_tiles: 4,
    /**
     * credits to see candidates details
     */
    credits: 5,
} as const;

export type PromotionTypesKeys = keyof typeof promotionTypes;
export type PromotionTypesValues = typeof promotionTypes[PromotionTypesKeys]
