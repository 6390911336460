/* eslint-disable max-len */
import {
    Container,
} from '@mui/material';
import { LoggedInUser } from '../../../types/auth.types';
import TawkTo from '../components/TawkTo';
import BuyJobs from '../containers/BuyJobs';
import CustomBR from '../foundation/CustomBR';
import Page from '../layout/base.page';

export type BuyJobsWrapperProps = {
    url: string
    user?: LoggedInUser
}

const BuyJobsWrapper = (props: BuyJobsWrapperProps) => <Page
    url={props.url}
    user={props.user}
    showBackToTop={false}
    pageHeaderTitle="Mploy - רכישת קרידטים" >
    <Container>
        <CustomBR />
        {/* <CenteredFlex>
            <Typography color="secondary" fontWeight="bold" variant="h3">Mploy - פרסום משרות</Typography>
        </CenteredFlex> */}
        <BuyJobs buyCredits />
        <TawkTo />
    </Container>
    <CustomBR />
</Page>;

export default BuyJobsWrapper;
